import { useEffect } from "react"
import { navigate } from "@reach/router"

function NotFound({ location }) {
  useEffect(() => {
    let refName = localStorage.getItem("refName")
    navigate(`/ref/${refName}${location.pathname}`)
  }, [location])

  return null
}

export default NotFound
